<template>
	<div class="w-full h-full relative overflow-hidden">
		<div :class="['grid grid-rows-2 lg:grid-rows-none lg:grid-cols-2']">
			<div :class="['column-left lg:h-screen col-span-1 bg-question bg-question-' + questionNumber]"></div>
			<div :class="['column-right lg:h-screen col-span-1 flex items-center justify-left relative -top-10 lg:mt-0']">
				<div class="mx-auto lg:mx-0 lg:-ml-14 bg-white rounded-3xl px-6 py-8 xl:p-10 w-11/12 lg:w-8/12">
					<div class="font-bold text-2xl lg:text-4xl w-full lg:w-10/12 m-auto text-center">{{ activeQuestion.title }}</div>
					<div class="mt-10 flex flex-col gap-4">
						<FunButton @click="answerClicked(answer)" class="w-full" v-for="answer in activeQuestion.answers" :key="answer.id">{{ answer.title }}</FunButton>
						<FunButton @click="homeClicked" class="bg-cloudy w-full">
							<div class="text-black flex flex-row items-center justify-center">
								<FunIcon :icon="'ArrowLeft'" :size="30" class="pr-1" />
								{{ lang.buttons.return_to_home }}
							</div>
						</FunButton>
					</div>
				</div>
			</div>
		</div>
		
		<result-dialog-prompt @confirmed="resultDialogPromptConfirmed" :visible="isResultDialogVisible" v-if="selectedAnswer" :question="activeQuestion" :answer="selectedAnswer"></result-dialog-prompt>
	</div>
</template>

<script>
import { en } from '../lang/lang.js'
import FunButton from '../components/FunButton.vue'
import FunIcon from '../components/FunIcon.vue'
import ResultDialogPrompt from '../components/ResultDialogPrompt.vue'
import questionsAndAnswers from '@/lang/en_questions.json'

export default {
	name: 'QuestionPage',
	components: {
		FunButton,
		FunIcon,
		ResultDialogPrompt,
	},
	props: {
	},
	data() {
		return {
			isResultDialogVisible: false,
			selectedAnswer: null,
		}
	},
	setup() {
		return {
			en,
			questionsAndAnswers,
		}
	},
	computed: {
		lang() {
			return this.en;
		},
		questionNumber() {
			return this.$route.params.id
		},
		activeQuestion() {
			return this.questionsAndAnswers.questions[this.questionNumber - 1]
		},
	},
	methods: {
		homeClicked() {
			this.$router.push('/progress')
		},
		answerClicked(answerSelection) {
			this.selectedAnswer = answerSelection

			let that = this
			setTimeout(function() { 
				that.isResultDialogVisible = true
			}, 200);
		},
		resultDialogPromptConfirmed(payload) {
			let newAnswerSet = this.$store.state.answers
			newAnswerSet[payload.question] = payload.correct
			this.$store.commit('updateAnswer', newAnswerSet)

			if(payload.correct) {
				this.selectedAnswer = null
				this.$router.push('/progress')
			} else
				this.selectedAnswer = null
		}
	},
	mounted() {
		let hasGameBeenStartedBefore = this.$store.state.hasStarted

		//	Have they already started this game before?
		if(!hasGameBeenStartedBefore) {

			//	They have decoded a QR code, but have not played before
			//	We will push them to the start page, but first we will
			//	set the question they scanned to redirect to when they
			//	pass the start page
			this.$store.commit('setRedirectTo', `/questions/${this.$route.params.id}`)

			//	Redirect them to home
			this.$router.push('/')
		}

	}
}
</script>

<style scoped>
.bg-question {
	background-size: cover;
	background-position: center center;
}
.bg-question-1 {
	background-image: url(@/assets/images/background-question-1.svg);
}
.bg-question-2 {
	background-image: url(@/assets/images/background-question-2.svg);
}
.bg-question-3 {
	background-image: url(@/assets/images/background-question-3.svg);
}
.bg-question-4 {
	background-image: url(@/assets/images/background-question-4.svg);
}
.bg-question-5 {
	background-image: url(@/assets/images/background-question-5.svg);
}
.bg-question-6 {
	background-image: url(@/assets/images/background-question-6.svg);
}

@media screen and (max-width: 1028px) {
	.column-left {
		height: 60vh;
	}
	.column-right {
		height: 20vh;
		bottom: 0;
	}
}

@media screen and (max-width: 700px) {
	.column-left {
		height: 50vh;
	}
	.column-right {
		height: 30vh;
		bottom: 0;
	}
}
</style>
