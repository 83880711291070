<template>
	<transition name="route" mode="out-in" appear>
		<router-view></router-view>
	</transition>

	<div class="fixed z-50 top-0 xl:top-auto bottom-0 left-0 right-0 bg-white/90 xl:bg-cloudy/60 flex items-center justify-center p-6 font-bold" v-if="$store.state.bossMode && bossMenuOpen">
		<div class="xl:hidden text-black absolute left-2 bottom-2" v-if="bossMenuOpen" @click="bossMenuOpen = !bossMenuOpen"><FunIcon :icon="'Close'" :size="36" :iconClass="'text-black'" /></div>
		<div class="xl:hidden text-black absolute left-2 bottom-2" v-if="!bossMenuOpen" @click="bossMenuOpen = !bossMenuOpen"><FunIcon :icon="'Menu'" :size="36" :iconClass="'text-black'" /></div>
		<div class="grid grid-cols-none grid-rows-4 xl:grid-rows-none xl:grid-cols-4 items-center">
			<div class="row-span-1 xl:col-span-1 flex items-center justify-center text-3xl xl:text-base">Boss Mode</div>
			<div class="row-span-3 xl:col-span-3 flex xl:flex-row flex-col gap-3 w-full">
				<button class="bg-black rounded-2xl text-white px-4 py-2" @click="startClicked">Start Page</button>
				<button class="bg-black rounded-2xl text-white px-4 py-2" @click="progressClicked">Progress Page</button>
				<button class="bg-black rounded-2xl text-white px-4 py-2" @click="devClicked">Developer Page</button>
				<button class="bg-black rounded-2xl text-white px-4 py-2" @click="resetGameClicked">Restart</button>
				<button class="bg-black rounded-2xl text-white px-4 py-2" @click="winGameClicked">Win</button>
				<button class="bg-black rounded-2xl text-white px-4 py-2 flex flex-row items-center" @click="toggleSoundClicked">
					<FunIcon :icon="soundIcon" :size="40" :class="['pl-3', {'animate-pulse': soundIcon === 'VolumeHigh'}]" />
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { useSound } from '@vueuse/sound'
import konamiSound from '@/assets/sounds/konami.mp3'
import FunIcon from './components/FunIcon.vue'

export default {
	name: 'App',
	components: {
		FunIcon,
	},
	data() {
		return {
			bossMenuOpen: false,
		}
	},
	setup() {
		const konamiSoundPlayer = useSound(konamiSound, {volume: 0.05})
		return { konamiSoundPlayer }
	},
	computed: {
		soundIcon() {
			return this.$store.state.soundMode ? "VolumeHigh" : "VolumeOff"
		},
		isMobile() {
			return this.$store.state.activeVersion == "mobile"
		},
		isDesktop() {
			return this.$store.state.activeVersion == "desktop"
		},
	},
	methods: {
		checkScreenSize() {
			if(window.screen.width < 600) {
				this.$store.commit('updateVersion', 'mobile')
			} else {
				this.$store.commit('updateVersion', 'desktop')
			}

			console.log(`App is running in ${this.$store.state.activeVersion} mode`)
		},
		konamiCodeEntered(state) {
			if(this.$store.state.soundMode)
				this.konamiSoundPlayer.play()

			let mode = (this.$store.state.bossMode == true) ? false : true
			this.$store.commit('updateBossMode', mode)
			this.bossMenuOpen = true

			console.log(`App is ${mode ? 'in boss' : 'not in boss'} mode`)
		},
		resetGameClicked() {
			this.$store.commit('resetGame')
			this.$store.commit('resetCamera')
			this.$router.push('/')
		},
		winGameClicked() {
			this.$store.commit('winGame')
		},
		startClicked() {
			this.$router.push('/')
		},
		progressClicked() {
			this.$router.push('/progress')
		},
		toggleSoundClicked() {
			this.$store.commit('updateSoundMode', (this.$store.state.soundMode == true) ? false : true)
		},
		devClicked() {
			this.$router.push('/dev')
		},
	},
	watch: {
		$route(to, from) {
			this.$gtag.pageview(to)
			
			console.log(`Navigating to ${to.name}`)
		}
	},
	mounted() {
		console.log('App.vue mounted')

		this.emitter.on('konami-code-entered', this.konamiCodeEntered)
		this.checkScreenSize()

		if(this.$store.state.bossMode) this.bossMenuOpen = true

		this.$store.commit('resetCamera')

		console.log(`Has camera been checked? ${this.$store.state.checkedForCamera ? 'Yes' : 'No'}`)
		console.log(`Does user have camera? ${this.$store.state.hasCamera ? 'Yes' : 'No'}`)
	}
}
</script>

<style>
@import '@/assets/fonts.css';

* {
  touch-action: manipulation;
}

html {
	width: 100vw;
	height: 100%;
}

body {
	width: 100%;
	height: 100%;
	background: #E0EDEF;
	color: #004848;
	font-family: "Frutiger LT Std";
}

#app {
	width: 100%;
	height: 100%;
	display: flex;
}

.header {
	font-family: "Frutiger LT Std Black";
}

.route-enter-from {
	opacity: 0;
	transform: translateY(0px)
}

.route-enter-active,
.route-leave-active {
	transition: all .5s ease-in-out
}

.route-leave-to {
	opacity: 0;
	transform: translateY(0px)
}
</style>
