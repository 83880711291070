<template>
	<div class="w-full h-full flex items-center justify-center relative p-10" style="padding-bottom: 10rem" v-if="!$store.state.bossMode">
		<div class="text-3xl">You must access Boss Mode first</div>
	</div>
	<div class="w-full relative p-10" style="padding-bottom: 10rem" v-else>
        <div class="text-2xl font-medium mb-4">Application data</div>
        <div class="text-xl mb-4 border-b border-gray-400">Titles</div>
		<div :class="['grid grid-cols-6']" v-for="(idx, title) in titles" :key="title">
			<div :class="['col-span-1']">{{ title }}</div>
			<div :class="['col-span-5']">{{ titles[title] }}</div>
        </div>
        <div class="text-xl mt-8 mb-4 border-b border-gray-400">Content</div>
		<div :class="['grid grid-cols-6']" v-for="(idx, item) in content" :key="item">
			<div :class="['col-span-1']">{{ item }}</div>
			<div :class="['col-span-5']">{{ content[item] }}</div>
        </div>
        <div class="text-xl mt-8 mb-4 border-b border-gray-400">Buttons</div>
		<div :class="['grid grid-cols-6']" v-for="(idx, button) in buttons" :key="button">
			<div :class="['col-span-1']">{{ button }}</div>
			<div :class="['col-span-5']">{{ buttons[button] }}</div>
        </div>
        <div class="text-xl mt-8 mb-4 border-b border-gray-400">Questions &amp; Answers</div>
		<div :class="['my-4']" v-for="(question) in questionsAndAnswers.questions" :key="question.id">
			<div :class="''">{{ question.id }}. {{ question.title }}</div>
			<div :class="['grid grid-cols-2']">
                <div class="col-span-1">
                    <div class="pl-4" v-for="answer in question.answers" :key="answer.id">
                        <div class="flex flex-cols gap-4 text-sm">
                            <div :class="{'font-bold text-green-600': answer.correct}">{{ answer.id }}. {{ answer.title }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-span-1 pl-4">
                    <div class="">{{ question.tips.title }}</div>
                    <div class="flex flex-cols pl-4 gap-4 text-sm" v-for="(tip) in question.tips.items" :key="tip">
                        <div :class="''">{{ tip.text }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-xl mt-8 mb-4 border-b border-gray-400">Sound Test</div>
		<div :class="['grid grid-cols-6 gap-4']">
            <fun-button :sound="sound" class="col-span-1 w-56" v-for="sound in sounds" :key="sound">{{ sound }}</fun-button>
        </div>
        <div class="text-xl mt-8 mb-4 border-b border-gray-400">Testing</div>
		<div :class="['grid grid-cols-6 gap-4']">
            <fun-button class="col-span-1 w-56" @mouseup="resetCameraClicked">Reset Camera</fun-button>
        </div>
    </div>
</template>

<script>
import { en } from '../lang/lang.js'
import FunButton from '../components/FunButton.vue'
import FunIcon from '../components/FunIcon.vue'
import questionsAndAnswers from '@/lang/en_questions.json'

export default {
	name: 'DevPage',
	components: {
		FunButton,
		FunIcon,
	},
	props: {
	},
	data() {
		return {
            sounds: ['cheer', 'correct-answer', 'forbidden', 'hey', 'konami', 'mouse-over', 'next', 'swish', 'wrong-answer'],
		}
	},
	setup() {
		return {
			en,
            questionsAndAnswers,
		}
	},
	computed: {
		lang() {
			return this.en;
		},
        titles() {
            return this.en.titles;
		},
        buttons() {
            return this.en.buttons;
		},
        content() {
            return this.en.content;
        }
	},
	methods: {
		resetCameraClicked() {
			this.$store.commit('resetCamera')
		}
	},
	mounted() {
		
	}
}
</script>

<style scoped>

</style>
