<template>
	<div class="w-full h-full page relative">
		<div class="w-full h-full xl:w-92 px-4 xl:px-auto mt-8 lg:mt-6 xl:mt-12">
			<div class="font-bold text-3xl xl:text-6xl text-center leading-none w-11/12 md:w-84 lg:w-5/12 xl:w-8/12 m-auto" v-html="cr2br(lang.titles.progress)"></div>
			<div class="mt-4 text-xl text-center leading-tight w-full m-auto" v-if="hasCamera && isCameraTestComplete">{{ lang.content.progress_with_camera }}</div>
			<div class="mt-4 text-xl text-center leading-tight w-full m-auto" v-else-if="!hasCamera && isCameraTestComplete">{{ lang.content.progress_without_camera }}</div>
			<div class="mt-4 text-xl text-center leading-tight w-full m-auto" v-if="!hasCameraBeenTested">{{ lang.content.progress_detecting }}</div>

			<div class="m-auto mt-5 xl:mt-10 relative">
				<div class="grid grid-cols-2 xl:grid-cols-3 w-full xl:w-6/12 relative gap-4 m-auto">
					<div :class="['relative col-span-1 w-full h-32 sm:h-28 md:h-60 lg:h-60 xl:h-52 m-auto bg-no-repeat bg-cover bg-center', itemClasses, {'pointer-events-none': this.$store.state.answers['question' + idx]}]" @mouseover="categoryMouseOver(idx)" @click.prevent="categoryItemClicked(idx)" v-for="idx in (1,2,3,4,5,6)" :key="idx" :style="{'background-image': 'url(' + require(`@/assets/images/element-select-${idx}.svg`) + ')'}">
						<div class="absolute bg-smokey/75 left-0 right-0 top-0 bottom-0 rounded-3xl overflow-hidden w-full h-full bg-no-repeat bg-center" v-if="this.$store.state.answers['question' + idx]" :style="{'background-size': '30%','background-image': 'url(' + require('@/assets/images/element-check-mark.svg') + ')'}"></div>
					</div>
					<div class="absolute left-0 right-0 top-0 bottom-0 z-20 flex justify-center items-center" v-if="hasCamera">
						<div class="w-full px-8 xl:px-20" @click="scanCodeClicked">
							<fun-button @mouseup="scanCodeClicked" class="w-full xl:px-32">
								<fun-icon :icon="'DataMatrixScan'" :size="40" class="text-white pr-2 relative" />
								{{ lang.buttons.scan_now }}
							</fun-button>
						</div>
					</div>
				</div>

				<div class="text-black flex flex-row items-center justify-center mt-6 xl:mt-10 w-full xl:w-3/12 m-auto">
					<fun-button @mouseup="resetButtonClicked" class="bg-cloudy">
						<fun-icon :icon="'ArrowLeft'" :size="30" class="text-black pr-1 relative" />
						<span class="text-black">{{ lang.buttons.return_to_start }}</span>
					</fun-button>
				</div>
			</div>
		</div>

		<!--- Tests to see if there is camera support, then hides itself --->
		<qrcode-stream @init="scannerTestInit" class="hidden" v-if="!hasCameraBeenTested" />
		
		<scan-dialog-prompt :visible="isScanning" @closed="backButtonClicked" @decoded="questionDecoded"></scan-dialog-prompt>
		
		<complete-dialog-prompt @confirmed="completeDialogPromptConfirmed" :visible="isGameCompleted" />

		<dialog-prompt :title="lang.titles.already_answered" :button-label="lang.buttons.already_answered" @confirmed="dialogConfirmed" :visible="isDialogVisible" v-if="isDialogVisible">
			{{ lang.content.already_answered }}
		</dialog-prompt>
	</div>
</template>

<script>
import { useSound } from '@vueuse/sound'
import { QrcodeStream } from 'vue3-qrcode-reader'
import { en, cr2br } from '../lang/lang.js'
import FunButton from '../components/FunButton.vue'
import FunIcon from '../components/FunIcon.vue'
import CompleteDialogPrompt from '../components/CompleteDialogPrompt.vue'
import DialogPrompt from '../components/DialogPrompt.vue'
import ScanDialogPrompt from '../components/ScanDialogPrompt.vue'
import buttonClickSound from '../assets/sounds/next.mp3'
import buttonHoverSound from '../assets/sounds/swish.mp3'
import questionsAndAnswers from '@/lang/en_questions.json'

export default {
	name: 'ProgressPage',
	components: {
		FunButton,
		FunIcon,
		CompleteDialogPrompt,
		ScanDialogPrompt,
		DialogPrompt,
		QrcodeStream,
	},
	props: {
	},
	setup() {
		const buttonClickSoundPlayer = useSound(buttonClickSound, {volume: 0.2})
		const buttonHoverSoundPlayer = useSound(buttonHoverSound, {volume: 0.2})

		return {
			en,
			cr2br,
			buttonClickSoundPlayer,
			buttonHoverSoundPlayer,
		}
	},
	data() {
		return {
			isScanning: false,
			isDesktop: true,
			isCameraTestComplete: false,
			isDialogVisible: false,
		}
	},
	computed: {
		lang() {
			return this.en;
		},
		itemClasses() {
			//  When they are on desktop, but with no camera, the questions have different styles applied
			if(this.isDesktop && !this.hasCamera && this.isCameraTestComplete)
				return "rounded-3xl border-4 border-gray-100 cursor-pointer transition duration-300 ease-in-out z-0 lg:hover:relative lg:hover:z-50 lg:hover:drop-shadow-lg lg:hover:border-white lg:hover:-translate-y-2 lg:hover:scale-125"
			
			return "rounded-3xl border-4 border-gray-100"
		},
		isGameCompleted() {
			let playerAnswers = this.$store.state.answers
			let totalCorrect = 0
			for(let question in playerAnswers) {
				if(playerAnswers[question]) totalCorrect += 1
			}
			return totalCorrect === 6
		},
		hasCameraBeenTested() {
			return this.$store.state.checkedForCamera
		},
		hasCamera() {
			return this.$store.state.hasCamera
		}
	},
	methods: {
		scanCodeClicked() {
			this.isScanning = true
		},
		backButtonClicked() {
			this.isScanning = false
		},
		async scannerTestInit(promise) {      
			return this.scannerInit(promise)
		},
		async scannerInit(promise) {
			console.log("Camera: checking...")
			try {
				console.log("Camera: Ready")
				const { capabilities } = await promise
				this.$store.commit('updateHasCamera', true)
				this.$gtag.event(`camera_state_enabled`, {'event_category': 'camera_state', 'event_label': `User has camera support on their device`})
			} catch (error) {
				let analyticsCameraState = 'none'
				let analyticLabel = `User does not have a camera on their device`

				if (error.name === 'NotAllowedError') {
					console.log("Camera: Not allowed to access")
					analyticsCameraState = 'denied'
					analyticLabel = `User has denied access to their camera on their device`
				} else if (error.name === 'NotFoundError') {
					console.log("Camera: No camera")
					analyticsCameraState = 'none'
					analyticLabel = `User does not have a camera on their device`
				} else if (error.name === 'NotSupportedError') {
					console.log("Camera: Not supported")
					analyticsCameraState = 'unsupported'
					analyticLabel = `User does not have a camera on their device`
				} else if (error.name === 'NotReadableError') {
					console.log("Camera: In use")
					analyticsCameraState = 'in_use'
					analyticLabel = `User is using their camera for another application right now`
				} else if (error.name === 'OverconstrainedError') {
					console.log("Camera: No camera")
					analyticsCameraState = 'error'
					analyticLabel = `User does not have a camera on their device`
				} else if (error.name === 'StreamApiNotSupportedError') {
					console.log("Camera: No camera")
					analyticsCameraState = 'error'
					analyticLabel = `User does not have a camera on their device`
				}

				this.$gtag.event(`camera_state_${analyticsCameraState}`, {'event_category': 'camera_state', 'event_label': analyticLabel})

				this.$store.commit('updateHasCamera', false)
			} finally {
				this.$store.commit('updateCameraChecked', true)
				this.isCameraTestComplete = true
			}
		},
		categoryItemClicked(categoryItem) {
			//  They can only click the items if they don't have a camera
			if(!this.isCameraTestComplete) return;
			if(this.hasCamera) return;
			if(this.$store.state.answers['question' + categoryItem]) return;

			this.$gtag.event(`question_${categoryItem}_selected`, {'event_category': 'button_click', 'event_label': `User selected question ${categoryItem}`})

			if(this.$store.state.soundMode) this.buttonClickSoundPlayer.play()
			this.$router.push(`/questions/${categoryItem}`)
		},
		categoryMouseOver(categoryItem) {
			let hasAnswered = this.$store.state.answers[`question${categoryItem}`]
			if(!hasAnswered && this.$store.state.soundMode) this.buttonHoverSoundPlayer.play()
		},
		resetButtonClicked() {
			this.$gtag.event(`game_reset`, {'event_category': 'game_reset', 'event_label': `User has been reset from the progress page`})
			this.$store.commit('resetGame')
			this.$router.push(`/`)
		},
		questionDecoded(urlData) {
			let hasAnsweredQuestionBefore = this.$store.state.answers[`question${urlData.param}`]

			//	Did they already answer this question?
			if(hasAnsweredQuestionBefore) {
				this.isScanning = false
				this.isDialogVisible = true
				return
			}

			if(urlData)
				this.$router.push(`/${urlData.page}/${urlData.param}`)
				
		},
		dialogConfirmed() {
			this.isDialogVisible = false
			this.isScanning = true
		}
	},
	mounted() {
		this.isScanning = false

		if(this.hasCameraBeenTested) {
			this.isCameraTestComplete = true
		}
	}
}
</script>

<style scoped>
</style>
