<template>
    <div :class="['fixed left-0 right-0 top-0 bottom-0 bg-black/30 z-50 flex items-center justify-center transition duration-300 ease-in-out', { 'opacity-0': !visible }]">
        <div :class="['bg-white border-3 border-black w-10/12 md:w-8/12 lg:w-4/12 rounded-2xl drop-shadow-lg transition duration-600 ease-in-out', { 'translate-y-full scale-0': !visible }]">
            <div class="flex flex-col py-2 text-center">
                <h1 class="px-8 py-4 text-2xl md:text-2xl font-bold border-b">{{ title }}</h1>
                <div class="px-8 py-4"><slot></slot></div>
                <div class="px-8 py-4">
                    <FunButton @click="$emit('confirmed', title)">{{ buttonLabel }}</FunButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FunButton from './FunButton.vue';

export default {
    props: {
        title: {
            required: true,
            type: String,
        },
        buttonLabel: {
            required: true,
            type: String,
            default: 'Accept',
        },
        visible: {
            default: false,
            type: Boolean
        }
    },
    components: { FunButton },
    data() {
        return {
            
        };
    },
    setup() {
        return {
        };
    },
    computed: {
    },
    methods: {
        acceptClicked() {
            this.$emit('confirmed', this.title)
        }
    },
    mounted() {
        console.log('DialogPrompt.vue mounted')
    }
}
</script>

<style scoped>
</style>