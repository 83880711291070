<template>
	<div :class="['fixed left-0 right-0 top-0 bottom-0 bg-black/60 z-50 flex items-center justify-center transition duration-300 ease-in-out', { 'pointer-events-none opacity-0': !visible }]">
		<div :class="['bg-white border-3 border-black rounded-2xl drop-shadow-lg transition duration-600 delay-300 ease-in-out', { 'w-11/12 sm:w-8/12 md:w-6/12 lg:w-6/12 lg:max-w-dialog xl:5/12': answer.correct, 'w-11/12 md:w-6/12 lg:w-3/12': !answer.correct, 'translate-y-full scale-0': !visible }]">
			<div :class="['xl:grid xl:grid-rows-none xl:grid-cols-2 max-h-screen']">
				<div :class="['xl:flex xl:flex-col xl:py-2 text-center', {'xl:col-span-1': answer.correct, 'xl:col-span-2': !answer.correct}]">
					<div class="m-auto p-6 pb-0 w-full" v-if="answer.correct"><img src="@/assets/images/element-character-happy.svg" class="w-6/12 xl:w-8/12 m-auto" rel="preload" /></div>
					<div class="m-auto p-6 pb-0 w-full" v-else><img src="@/assets/images/element-character-sad.svg" class="w-6/12 xl:w-8/12 m-auto" rel="preload" /></div>
					<h1 class="px-8 py-4 xl:py-8 xl:pb-4 text-4xl md:text-3xl lg:text-4xl font-bold">{{ title }}</h1>
					<div class="px-8 pt-2 pb-6 xl:py-1 xl:px-8 xl:py-4">
						<div class="text-xl xl:text-2xl" v-if="answer.correct">{{ lang.content.correct_answer }}</div>
						<div class="text-xl xl:text-lg" v-else>{{ lang.content.incorrect_answer }}</div>
					</div>
					<div class="px-10 pt-4 pb-8 hidden xl:block">
						<FunButton @click="confirmedClicked(question, answer)">
							{{ answer.correct ? lang.buttons.return_to_home : lang.buttons.try_again }}
						</FunButton>
					</div>
				</div>
				<div :class="['xl:col-span-1 xl:flex xl:flex-col text-center justify-center']" v-if="answer.correct">
					<div class="tips p-4 xl:p-8 mx-8 mt-0 mb-4 xl:my-10 rounded-3xl flex flex-col justify-center items-center h-full">
						<div class="text-lg xl:text-3xl title mb-4">{{ question.tips.title }}</div>
						<div class="text-sm xl:text-base bullet text-left mb-2 xl:mb-4 self-start" v-for="item in question.tips.items" :key="item.text">{{ item.text }}</div>
					</div>
				</div>
				<div class="px-8 pt-2 xl:pt-4 pb-8 block xl:hidden">
					<FunButton @click="confirmedClicked(question, answer)">
						{{ answer.correct ? lang.buttons.return_to_home : lang.buttons.try_again }}
					</FunButton>
				</div>
			</div>
		</div>
		<!-- Stars -->
		<div id="scene" class="absolute left-0 right-0 top-0 bottom-0 pointer-events-none -z-10" v-if="answer.correct">
			<div :class="['transition transition-all ease-in-out star', starClass(star)]" v-for="star in stars" :key="star">
				<img src="@/assets/images/element-star.svg" :class="['twinkle']" rel="preload" />
			</div>
		</div>
	</div>
</template>

<script>
import { en } from '../lang/lang.js'
import { useSound } from '@vueuse/sound'
import Parallax from 'parallax-js'
import FunButton from './FunButton.vue'
import FunIcon from './FunIcon.vue'
import cheerSound from '../assets/sounds/cheer.mp3'
import wrongAnswerSound from '../assets/sounds/wrong-answer.mp3'

export default {
	props: {
		answer: {
			required: true,
			type: Object
		},
		question: {
			required: true,
			type: Object
		},
		visible: {
			default: false,
			type: Boolean
		}
	},
	components: { FunButton, FunIcon },
	data() {
		return {
			stars: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
			parallaxInstance: null,
		}
	},
	setup() {
		const cheerSoundPlayer = useSound(cheerSound, {volume: 0.1})
		const wrongAnswerSoundPlayer = useSound(wrongAnswerSound, {volume: 0.1})

		return {
			en,
			cheerSoundPlayer,
			wrongAnswerSoundPlayer
		}
	},
	watch: {
		visible(val) {
			this.$forceUpdate()
		},
		answer(val) {
			this.$forceUpdate()
		}
	},
	computed: {
		lang() {
			return this.en;
		},
		title() {
			return this.answer.correct ? this.lang.titles.youre_right : this.lang.titles.are_you_sure
		},
	},
	methods: {
		starClass(star) {
			if(!this.visible) return 'star-below'
			return ['star-' + star]
		},
		setupParallax() {
			let scene = document.getElementById('scene')
			this.parallaxInstance = new Parallax(scene)
		},
		confirmedClicked(question, answer) {
			this.cheerSoundPlayer.stop()
			this.wrongAnswerSoundPlayer.stop()
			this.$emit('confirmed', {question: `question${question.id}`, correct: answer.correct})
		},
	},
	mounted() {
		let that = this
		that.cheerSoundPlayer.stop()
		that.wrongAnswerSoundPlayer.stop()
		
		if(!this.$store.state.soundMode) return

		setTimeout(function() {
			if(that.answer.correct) {
				that.cheerSoundPlayer.play()
			} else {
				that.wrongAnswerSoundPlayer.play()
			}
		}, 150)

		setTimeout(function() {
			//that.setupParallax()
		}, 1000)
	}
}
</script>

<style scoped>
.tips {
	background: #29588D;
}

.title {
	color: white;
}

.bullet {
	background: url(@/assets/images/element-small-arrow-bullet.svg);
	background-repeat: no-repeat;
	background-size: 14px;
	color: white;
	padding-left: 28px;
}

.star {
	position: absolute;
	transition: all;
	transition-timing-function: ease-in-out;
	transition-duration: 800ms;
}

.twinkle {
	animation-name: twinkle-animation;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: ease-out;
	position: relative;
}

.star-below {
	transform: translateX(0%) scale(0%);
	bottom: 50vh;
	left: 20vw;
	right: auto;
	top: 50vh;
}

.star-1 {
	transform: translateX(-90%) scale(80%);
	left: 13%;
	top: 2%;
	bottom: auto;
}


.star-2 {
	transform: translateX(-20%) scale(30%);
	opacity: 0.6;
	left: 18%;
	top: -4%;
	bottom: auto;
}

.star-2 .twinkle { animation-duration: 2s; }

.star-3 {
	transform: translateX(-40%) scale(80%);
	left: 38%;
	top: 15%;
	bottom: auto;
	transition-duration: 200ms;
}

.star-4 {
	transform: translateX(-10%) scale(30%);
	opacity: 0.6;
	left: 50%;
	top: 2%;
	bottom: auto;
}

.star-4 .twinkle { animation-duration: 2s; }

.star-5 {
	transform: translateX(40%) scale(30%);
	opacity: 0.6;
	right: 20%;
	top: 7%;
	bottom: auto;
}

.star-5 .twinkle { animation-duration: 2s; }

.star-6 {
	transform: translateX(30%) scale(80%);
	right: 13%;
	top: 2%;
	bottom: auto;
}

.star-7 {
	transform: translateX(-20%) scale(30%);
	opacity: 0.6;
	left: 13%;
	top: 35%;
	bottom: auto;
}

.star-7 .twinkle { animation-duration: 2s; }

.star-8 {
	transform: translateX(20%) scale(30%);
	opacity: 0.6;
	right: 13%;
	top: 25%;
	bottom: auto;
}

.star-8 .twinkle { animation-duration: 2s; }

.star-9 {
	transform: translateX(70%) scale(30%);
	opacity: 0.6;
	left: 18%;
	top: 50%;
	bottom: auto;
}

.star-9 .twinkle { animation-duration: 2s; }

.star-10 {
	transform: translateX(30%) scale(70%);
	right: 12%;
	top: 48%;
	bottom: auto;
	transition-duration: 300ms;
}

.star-11 {
	transform: translateX(30%) scale(80%);
	left: 12%;
	bottom: 30%;
	top: auto;
}

.star-12 {
	transform: translateX(30%) scale(30%);
	opacity: 0.6;
	right: 32%;
	bottom: 20%;
	top: auto;
}

.star-12 .twinkle { animation-duration: 2s; }

.star-13 {
	transform: translateX(30%) scale(30%);
	opacity: 0.6;
	right: 24%;
	bottom: 28%;
	top: auto;
}

.star-13 .twinkle { animation-duration: 2s; }

.star-14 {
	transform: translateX(-30%) scale(30%);
	opacity: 0.6;
	bottom: 4%;
	left: 18%;
	top: auto;
}

.star-14 .twinkle { animation-duration: 2s; }  

.star-15 {
	transform: translateX(-10%) scale(80%);
	bottom: 7%;
	left: 26%;
	top: auto;
	transition-duration: 300ms;
}

.star-16 {
	transform: translateX(-50%) scale(30%);
	opacity: 0.6;
	bottom: 12%;
	left: 44%;
	top: auto;
}

.star-16 .twinkle { animation-duration: 2s; }

.star-17 {
	transform: translateX(30%) scale(80%);
	bottom: 7%;
	right: 40%;
	top: auto;
}

.star-18 {
	transform: translateX(30%) scale(70%);
	bottom: 8%;
	right: 17%;
	top: auto;
	transition-duration: 300ms;
}

@media only screen and (max-width: 700px) {
	.star-5,
	.star-16,
	.star-15,
	.star-17 {
		display: none;
	}
}

@keyframes twinkle-animation {
	0% {
		transform: translateY(10%) rotate(3deg);
	}
	100% {
		transform: translateY(-10%) rotate(-3deg);
	}
}
</style>