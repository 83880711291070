<template>
	<div :class="['fixed left-0 right-0 top-0 bottom-0 bg-smokey/75 z-50 flex items-center justify-center transition duration-300 ease-in-out', { 'pointer-events-none opacity-0': !visible }]">
		<div :class="['bg-white border-3 border-black rounded-2xl drop-shadow-lg transition duration-600 delay-300 ease-in-out w-11/12 xl:w-4/12', {'translate-y-full scale-0': !visible }]">
			<div class="m-auto p-10 relative">
				<div class="text-xl xl:text-xl text-center leading-tight m-auto mb-8">{{ lang.titles.scan_prompt }}</div>
				<div class="relative m-auto scanner-container">
					<div class="absolute left-0 right-0 bottom-0 top-0 z-40 pointer-events-none items-center flex">
						<div class="w-6/12 h-1 m-auto bg-white/60 rounded-full scanline">&nbsp;</div>
					</div>
					<img src="@/assets/images/overlay-camera.svg" rel="preload" :class="['absolute top-5 left-0 right-0 bottom-0 scanner-overlay m-auto z-50']" />
					<div class="rounded-3xl overflow-hidden m-auto scanner" v-if="visible">
						<qrcode-stream @decode="qrcodeDecoded" @init="scannerInit" :camera="cameraView" />
					</div>
				</div>
				<div class="w-full m-auto flex mt-10">
					<fun-button @mouseup="backButtonClicked" class="bg-cloudy">
						<fun-icon :icon="'ArrowLeft'" :size="30" class="text-black pr-1 relative" />
						<span class="text-black">{{ lang.buttons.go_back }}</span>
					</fun-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { en } from '../lang/lang.js'
import { useSound } from '@vueuse/sound'
import { QrcodeStream } from 'vue3-qrcode-reader'
import FunButton from './FunButton.vue'
import FunIcon from './FunIcon.vue'
import dingSound from '../assets/sounds/correct-answer.mp3'

export default {
	props: {
		visible: { 
			type: Boolean, 
			default: false,
		},
	},
	components: { 
		FunButton, 
		FunIcon, 
		QrcodeStream 
	},
	data() {
		return {
			cameraView: "off",
		}
	},
	setup() {
		const dingSoundPlayer = useSound(dingSound, {volume: 0.1})
		
		return {
			en,
			dingSoundPlayer,
		}
	},
	watch: {
	},
    computed: {
		lang() {
			return this.en;
		},
    },
	methods: {
		backButtonClicked() {
            this.$gtag.event('back_to_progress', {'event_category': 'button_click', 'event_label': 'Back to Progress Page'})
			this.$emit('closed')
		},
		qrcodeDecoded(decodedString) {
			let urlParts = decodedString.split('https://')
			let pathParts = urlParts[1].split('/')
			let url = pathParts[0]
			let page = pathParts[1]
			let param = pathParts[2]
			let analyticId = param ? `${page}_${param}` : `${page}`
			
			this.$gtag.event(`qr_code_decoded_${analyticId}`, {'event_category': 'qr_code_decoded', 'event_label': `QR Code Decoded for ${page} ${param}`})
			
			this.$emit('decoded', {url: url, page: page, param: param})
		},
		async scannerInit(promise) {
			const { capabilities } = await promise
		}
	},
	mounted() {
		console.log('ScanDialogPrompt.vue mounted')
		this.cameraView = "auto"
	},
}
</script>

<style>
.scanline {
	animation-name: scanline;
	animation-duration: 1.3s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: ease-in-out;
}

.scanner-container {
	width: 320px;
	height: 250px;
}

.scanner {
	width: 320px;
	height: 250px;
}

.scanner-overlay {
	width: 340px;
	height: 300px;
}

@media screen and (max-width: 600px) {
	.scanner-container {
		width: 290px;
		height: 230px;
	}

	.scanner {
		width: 290px;
		height: 230px;
	}
}

@keyframes scanline {
	0% {
		transform: translateY(-70px);
		opacity: 0.4;
	}
	10% {
		opacity: 1;
	}
	40% {
		opacity: 0.2;
	}
	70% {
		opacity: 0.6;
	}
	100% {
		transform: translateY(70px);
		opacity: 0.3;
	}
}
</style>