<template>
	<div :class="['fixed left-0 right-0 top-0 bottom-0 bg-black/60 z-50 flex items-center justify-center transition duration-300 ease-in-out', { '': visible, 'pointer-events-none opacity-0 -z-1': !visible }]">
		<div :class="['bg-white border-3 border-black w-10/12 lg:w-8/12 xl:w-3/12 rounded-2xl drop-shadow-lg transition duration-600 ease-in-out', { '': visible, 'translate-y-full scale-0': !visible }]">
			<div :class="['flex flex-col py-2 text-center']">
				<div class="m-auto p-6 w-full"><img src="@/assets/images/element-character-head-only.svg" class="character" rel="preload" /></div>
				<h1 class="px-8 py-4 text-4xl md:text-3xl lg:text-4xl font-bold">{{ lang.titles.completed }}</h1>
				<div class="px-10 py-4">
					<div class="text-lg">{{ lang.content.completed }}</div>
				</div>
				<div class="px-10 py-4 flex flex-col gap-4">
					<FunButton @click="confirmedClicked(question, answer)">{{ lang.buttons.play_again }}</FunButton>
					<FunButton @click="downloadClicked">{{ lang.buttons.download_your_prize }}</FunButton>
				</div>
			</div>
		</div>
		<!-- Stars -->
		<div id="scene" class="absolute left-0 right-0 top-0 bottom-0 pointer-events-none -z-10">
			<div :class="['transition transition-all ease-in-out star', starClass(star)]" v-for="star in stars" :key="star">
				<img src="@/assets/images/element-star.svg" :class="['twinkle']" rel="preload" />
			</div>
		</div>
	</div>
</template>

<script>
import { en } from '../lang/lang.js'
import Parallax from 'parallax-js'
import { useSound } from '@vueuse/sound'
import FunButton from './FunButton.vue'
import cheerSound from '../assets/sounds/cheer.mp3'

export default {
	props: {
		visible: {
			default: false,
			type: Boolean
		}
	},
	components: { FunButton },
	data() {
		return {
			stars: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
			parallaxInstance: null,
		}
	},
	setup() {
		const cheerSoundPlayer = useSound(cheerSound, {volume: 0.1})

		return {
			en,
			cheerSoundPlayer,
		}
	},
	watch: {
		visible(val) {
			this.$forceUpdate()
		}
	},
	computed: {
		lang() {
			return this.en;
		},
	},
	methods: {
		starClass(star) {
			if(!this.visible) return 'star-below'
			return ['star-' + star]
		},
		setupParallax() {
			let scene = document.getElementById('scene')
			this.parallaxInstance = new Parallax(scene)
		},
		confirmedClicked(question, answer) {
			this.cheerSoundPlayer.stop()
			this.$store.commit('resetGame')
			this.$router.push('/')
		},
		downloadClicked() {
			window.open('/HPL-Activity-book.pdf', "_blank");
		}
	},
	mounted() {
		console.log('CompleteDialogPrompt.vue mounted')

		let that = this
		setTimeout(function() {
			//that.setupParallax()
		}, 1000)
	}
}
</script>

<style scoped>
.tips {
	background: #29588D;
}

.character {
	width: 60%;
	margin: auto;
}

.star {
	position: absolute;
	transition: all;
	transition-timing-function: ease-in-out;
	transition-duration: 800ms;
}

.twinkle {
	animation-name: twinkle-animation;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: ease-out;
	position: relative;
}

.star-below {
	transform: translateX(0%) scale(0%);
	bottom: 50vh;
	left: 20vw;
	right: auto;
	top: 50vh;
}

.star-1 {
	transform: translateX(-90%) scale(80%);
	left: 13%;
	top: 2%;
	bottom: auto;
}


.star-2 {
	transform: translateX(-20%) scale(30%);
	opacity: 0.6;
	left: 18%;
	top: -4%;
	bottom: auto;
}

.star-2 .twinkle { animation-duration: 2s; }

.star-3 {
	transform: translateX(-40%) scale(80%);
	left: 38%;
	top: 15%;
	bottom: auto;
	transition-duration: 200ms;
	display: none;
}

.star-4 {
	transform: translateX(-10%) scale(30%);
	opacity: 0.6;
	left: 50%;
	top: 2%;
	bottom: auto;
	display: none;
}

.star-4 .twinkle { animation-duration: 2s; }

.star-5 {
	transform: translateX(40%) scale(30%);
	opacity: 0.6;
	right: 20%;
	top: 7%;
	bottom: auto;
}

.star-5 .twinkle { animation-duration: 2s; }

.star-6 {
	transform: translateX(30%) scale(80%);
	right: 13%;
	top: 2%;
	bottom: auto;
}

.star-7 {
	transform: translateX(-20%) scale(30%);
	opacity: 0.6;
	left: 13%;
	top: 35%;
	bottom: auto;
}

.star-7 .twinkle { animation-duration: 2s; }

.star-8 {
	transform: translateX(20%) scale(30%);
	opacity: 0.6;
	right: 13%;
	top: 25%;
	bottom: auto;
}

.star-8 .twinkle { animation-duration: 2s; }

.star-9 {
	transform: translateX(70%) scale(30%);
	opacity: 0.6;
	left: 18%;
	top: 50%;
	bottom: auto;
}

.star-9 .twinkle { animation-duration: 2s; }

.star-10 {
	transform: translateX(30%) scale(70%);
	right: 12%;
	top: 48%;
	bottom: auto;
	transition-duration: 300ms;
}

.star-11 {
	transform: translateX(30%) scale(80%);
	left: 12%;
	bottom: 30%;
	top: auto;
}

.star-12 {
	transform: translateX(30%) scale(30%);
	opacity: 0.6;
	right: 32%;
	bottom: 20%;
	top: auto;
}

.star-12 .twinkle { animation-duration: 2s; }

.star-13 {
	transform: translateX(30%) scale(30%);
	opacity: 0.6;
	right: 24%;
	bottom: 28%;
	top: auto;
}

.star-13 .twinkle { animation-duration: 2s; }

.star-14 {
	transform: translateX(-30%) scale(30%);
	opacity: 0.6;
	bottom: 4%;
	left: 18%;
	top: auto;
}

.star-14 .twinkle { animation-duration: 2s; }  

.star-15 {
	transform: translateX(-10%) scale(80%);
	bottom: 7%;
	left: 26%;
	top: auto;
	transition-duration: 300ms;
}

.star-16 {
	transform: translateX(-50%) scale(30%);
	opacity: 0.6;
	bottom: 12%;
	left: 44%;
	top: auto;
}

.star-16 .twinkle { animation-duration: 2s; }

.star-17 {
	transform: translateX(30%) scale(80%);
	bottom: 7%;
	right: 40%;
	top: auto;
}

.star-18 {
	transform: translateX(30%) scale(70%);
	bottom: 8%;
	right: 17%;
	top: auto;
	transition-duration: 300ms;
}

@media only screen and (max-width: 700px) {
	.star-5,
	.star-16,
	.star-15,
	.star-17 {
		display: none;
	}
}

@keyframes twinkle-animation {
	0% {
		transform: translateY(10%) rotate(3deg);
	}
	100% {
		transform: translateY(-10%) rotate(-3deg);
	}
}

</style>