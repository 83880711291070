<template>
	<div class="w-full h-full relative overflow-hidden">
		<div class="absolute left-0 right-0 top-0 bottom-0" id="scene" data-pointer-events="true">
			<div class="header w-full h-screen absolute mt-20 z-10 pointer-events-none">
				<div :class="['transition ease-in-out duration-700 text-xl mt-4 sm:mt-0 md:mt-0 lg:mt-0 sm:text-4xl md:text-5xl lg:text-4xl text-center leading-none w-12/12 md:w-12/12 lg:w-9/12 xl:w-9/12 m-auto', {'-translate-y-full opacity-0': isDisassembling}]" v-html="cr2br(lang.titles.home)"></div>
			</div>
			<div class="w-full h-screen"><img rel="preload" src="@/assets/images/element-sun.svg" :class="['transition ease-in-out duration-700 m-auto sun', {'translate-y-0 opacity-100': isAssembling || isAssembled, '-translate-y-90 opacity-0': isDisassembling}]" /></div>
			<div class="w-full h-screen"><img rel="preload" src="@/assets/images/element-cloud.svg" :class="['transition ease-in-out duration-700 delay-100 cloud-1 cloud-lg', {'translate-y-full opacity-0': isDisassembling}]" /></div>
			<div class="w-full h-screen"><img rel="preload" src="@/assets/images/element-cloud.svg" :class="['transition ease-in-out duration-700 delay-300 cloud-2 cloud-sm', {'translate-y-full opacity-0': isDisassembling}]" /></div>
			<div class="w-full h-screen"><img rel="preload" src="@/assets/images/element-cloud.svg" :class="['transition ease-in-out duration-700 delay-400 cloud-3 cloud-lg', {'translate-y-full opacity-0': isDisassembling}]" /></div>
			<div class="w-full h-screen"><img rel="preload" src="@/assets/images/element-cloud.svg" :class="['transition ease-in-out duration-700 delay-300 cloud-4 cloud-lg', {'translate-y-full opacity-0': isDisassembling}]" /></div>
			<div class="w-full h-screen"><img rel="preload" src="@/assets/images/element-cloud.svg" :class="['transition ease-in-out duration-700 delay-400 cloud-9 cloud-sm', {'translate-y-full opacity-0': isDisassembling}]" /></div>
			<div class="w-full h-screen"><img rel="preload" src="@/assets/images/element-cloud.svg" :class="['transition ease-in-out duration-700 delay-200 cloud-6 cloud-lg', {'translate-y-full opacity-0': isDisassembling}]" /></div>
			<div class="w-full h-screen"><img rel="preload" src="@/assets/images/element-cloud.svg" :class="['transition ease-in-out duration-700 cloud-7 cloud-lg', {'translate-y-full opacity-0': isDisassembling}]" /></div>
			<div class="w-full h-screen"><img rel="preload" src="@/assets/images/element-cloud.svg" :class="['transition ease-in-out duration-700 delay-300 cloud-10 cloud-sm', {'translate-y-full opacity-0': isDisassembling}]" /></div>
			<div class="w-full h-screen"><img rel="preload" src="@/assets/images/element-cloud.svg" :class="['transition ease-in-out duration-700 cloud-8 cloud-lg', {'translate-y-full opacity-0': isDisassembling}]" /></div>
			<div class="w-full h-screen"><img rel="preload" src="@/assets/images/element-cloud.svg" :class="['transition ease-in-out duration-700 cloud-5 cloud-sm', {'translate-y-full opacity-0': isDisassembling}]" /></div>
			<div class="w-full h-screen bg-home"></div>
			<div class="w-full h-screen z-30"><img rel="preload" src="@/assets/images/element-bird-1.svg" :class="['transition ease-in-out duration-500 bird-1', {'-translate-y-80 opacity-0': isDisassembling}]" /></div>
			<div class="w-full h-screen z-30"><img rel="preload" src="@/assets/images/element-butterfly-1.svg" :class="['transition ease-in-out duration-400 butterfly-1', {'-translate-y-60 opacity-0': isDisassembling}]" /></div>
			<div class="w-full h-screen z-30"><img rel="preload" src="@/assets/images/element-butterfly-2.svg" :class="['transition ease-in-out duration-200 butterfly-2', {'-translate-y-80 opacity-0': isDisassembling}]" /></div>
			<div class="w-full h-screen z-40">
				<div :class="['transition ease-in-out duration-700 button-start mb-10 xl:mb-auto m-auto', {'translate-y-40 opacity-0': isDisassembling}]">
					<div class="grid grid-cols-12 gap-2 px-8 m-auto xl:w-4/12">
						<fun-button @mouseup="startButtonClicked" class="col-span-8">{{ lang.buttons.lets_start }}</fun-button>
						<button @click="toggleSoundButtonClicked" class="col-span-4 lg:hover:-translate-y-1 lg:hover:scale-110 transition duration-300 ease-in-out bg-black flex items-center justify-center rounded-full border-gray-700 border-b-4 pt-1"><fun-icon :icon="soundIcon" :size="isMobile ? 26 : 30" :class="['text-white', {'animate-pulse': soundIcon === 'VolumeHigh'}]" /></button>
					</div>
				</div>
			</div>
		</div>
		<dialog-prompt :title="lang.titles.warning_confirm" :button-label="lang.buttons.warning_confirm" @confirmed="dialogConfirmed" :visible="isDialogVisible" v-if="!isDialogConfirmed">
			{{ lang.content.warning_confirm }}
		</dialog-prompt>
	</div>
</template>
	
<script>
import { en, cr2br } from '../lang/lang.js'
import Parallax from 'parallax-js'
import FunButton from '../components/FunButton.vue'
import FunIcon from '../components/FunIcon.vue'
import DialogPrompt from '../components/DialogPrompt.vue'

export default {
	name: 'HomePage',
	components: {
		FunButton,
		FunIcon,
		DialogPrompt,
	},
	data() {
		return {
			isAssembled: false,
			isAssembling: false,
			isDisassembling: false,
			isDialogVisible: false,
			isDialogConfirmed: false,
			parallaxInstance: null,
		}
	},
	setup() {
		return {
			en,
			cr2br,
		}
	},
	methods: {
		setupParallax() {
			let scene = document.getElementById('scene')
			this.parallaxInstance = new Parallax(scene)
		},
		showDialog() {
			this.isDialogVisible = true
		},
		startButtonClicked() {
			this.isDisassembling = true
			this.isAssembled = false
			if(this.parallaxInstance) this.parallaxInstance.disable()
			
			let that = this
			let redirectTo = this.$store.state.redirectTo
			let nextPage = '/progress'

			if(redirectTo) {
				nextPage = `${redirectTo}`
				this.$gtag.event(`game_started_qr_code_scan`, {'event_category': 'game_started', 'event_label': `User has started the game from a QR code scan`})
			} else {
				this.$gtag.event(`game_started_home_page`, {'event_category': 'game_started', 'event_label': `User has started the game from the home page`})
			}

			that.$store.commit('setHasStarted', true)

			setTimeout(function() {
				that.isDisassembling = false
				that.$router.push(nextPage).then(function() {
					that.$store.commit('setRedirectTo', null)
				})
			}, 500)
		},
		toggleSoundButtonClicked() {
			this.$store.commit('updateSoundMode', !this.$store.state.soundMode)
		},
		dialogConfirmed() {
			this.isDialogVisible = false

			let that = this
			setTimeout(function() {
				that.isDialogConfirmed = true
			}, 550)

			this.$gtag.event(`dialog_accepted`, {'event_category': 'dialog_accepted', 'event_label': `User has accepted the Home dialog`})
		}
	},
	computed: {
		lang() {
			return this.en;
		},
		isMobile() {
			return this.$store.state.activeVersion == "mobile"
		},
		isDesktop() {
			return this.$store.state.activeVersion == "desktop"
		},
		soundIcon() {
			return this.$store.state.soundMode ? "VolumeHigh" : "VolumeOff"
		},
	},
	mounted() {
		this.isDisassembling = false
		this.isAssembling = true
		this.isDialogConfirmed = true
		this.showDialog()

		let that = this
		setTimeout(function() {
			that.isAssembling = false
			that.isAssembled = true
			// if(that.isDesktop && !that.parallaxInstance)
			// 	that.setupParallax()
		}, 500);
	},
	created() {
		this.isDisassembling = false
		this.isAssembling = false
	}
}
</script>
	
<style scoped>
.bg-home {
	background-image: url(@/assets/images/home-bg-desktop.svg);
	background-size: contain;
	background-position: bottom center;
	background-repeat: no-repeat;
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.sun {
	top: -3em;
	width: 30vw;
	left: 50vw;
	margin-left: -15vw;
	position: absolute;
	height: auto;
}

.bird-1 {
	position: absolute;
	width: 5vw;
	right: 28vw;
	top: 30vh;
}

.butterfly-1 {
	position: absolute;
	width: 6vw;
	left: 27vw;
	top: 35vh;
}

.butterfly-2 {
	position: absolute;
	width: 8vw;
	right: 27vw;
	bottom: 16vh;
}

.button-start {
	width: 100%;
	position: absolute;
	text-align: center;
	bottom: 5vh;
}

.cloud-sm {
	width: 8vw;
	height: auto;
}

.cloud-md {
	width: 10vw;
	height: auto;
}

.cloud-lg {
	width: 20vw;
	height: auto;
}

.cloud-xl {
	width: 40vw;
	height: auto;
}

.cloud-1 {
	position: absolute;
	left: 10vw;
	top: 2vh;
}

.cloud-2 {
	position: absolute;
	right: 20vw;
	top: 2vh;
}

.cloud-3 {
	position: absolute;
	right: 17vw;
	top: 28vh;
}

.cloud-4 {
	position: absolute;
	left: -8vw;
	top: 30vh;
}

.cloud-5 {
	position: absolute;
	left: 38vw;
	top: 38vh;
}

.cloud-6 {
	position: absolute;
	right: -14vw;
	top: 50vh;
}

.cloud-7 {
	position: absolute;
	left: 22vw;
	bottom: 22vh;
}

.cloud-8 {
	position: absolute;
	right: 25vw;
	bottom: 22vh;
}

.cloud-9 {
	position: absolute;
	right: -3vw;
	bottom: 8vh;
}

.cloud-10 {
	position: absolute;
	left: -3vw;
	bottom: 8vh;
}

@media only screen and (max-width: 475px) {
	.bg-home {
		background-image: url(@/assets/images/home-bg-mobile.svg);
		background-size: 400%;
		background-position: bottom center;
		background-repeat: no-repeat;
	}

	.sun {
		transform: scale(2.4);
		top: 0;
	}

	.butterfly-1 {
		left: 10vw;
		top: 47vh;
		transform: scale(2.2);
		display: none;
	}

	.butterfly-2 {
		right: 3vw;
		bottom: 15vh;
		transform: scale(2);
		display: none;
	}

	.bird-1 {
		right: 28vw;
		top: 28vh;
		transform: scale(3.6);
		display: none;
	}

	.rainbow {
		transform: scale(2.4);
		bottom: 10vh;
	}

	.cloud-3 {
		top: 28vh;
		transform: scale(2.6);
	}

	.cloud-5 {
		transform: scale(2.2);
		left: 20vw;
		top: 47vh;
	}

	.cloud-7 {
		transform: scale(2);
		bottom: 19vh;
		left: 10vw;
	}
	.button-start {
		width: 100%;
		bottom: 0vh;
		margin-bottom: 1rem;
	}
}

@media only screen and (min-width: 476px) and (max-width: 699px) {	
	.bg-home {
		background-image: url(@/assets/images/home-bg-mobile.svg);
		background-size: 250%;
		background-position: bottom center;
		background-repeat: no-repeat;
	}

	.sun {
		transform: scale(1.6);
		top: 0;
	}

	.butterfly-1 {
		left: 13vw;
		top: 50vh;
		transform: scale(2.2);
	}

	.butterfly-2 {
		right: 8vw;
		bottom: 13vh;
		transform: scale(2);
	}

	.bird-1 {
		right: 28vw;
		top: 28vh;
		transform: scale(2.6);
	}

	.rainbow {
		transform: scale(2.2);
		bottom: 8vh;
	}

	.cloud-3 {
		transform: scale(2.2);
	}

	.cloud-5 {
		transform: scale(2.2);
		left: 30vw;
		top: 54vh;
	}

	.cloud-7 {
		transform: scale(1.5);
		bottom: 25vh;
		left: 18vw;
	}

	.button-start {
		width: 100%;
		bottom: 0vh;
		margin-bottom: 1rem;
	}
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
	.bg-home {
		background-image: url(@/assets/images/home-bg-desktop.svg);
		background-size: contain;
		background-position: bottom center;
		background-repeat: no-repeat;
	}

	.sun {
		transform: scale(1.6);
		top: 0;
	}

	.butterfly-1 {
		left: 20vw;
		transform: scale(1.8);
	}

	.butterfly-2 {
		right: 17vw;
		bottom: 20vh;
		transform: scale(1.8);
	}

	.bird-1 {
		right: 28vw;
		top: 28vh;
		transform: scale(1.8);
	}

	.cloud-3 {
		transform: scale(1.5);
	}

	.cloud-5 {
		transform: scale(1.5);
		left: 30vw;
		top: 50vh;
	}

	.cloud-7 {
		transform: scale(1.5);
		bottom: 25vh;
		left: 18vw;
	}

	.button-start {
		width: 100%;
		bottom: 0vh;
		margin-bottom: 1rem;
	}
}

@media only screen and (min-width: 900px) and (max-width: 1100px) {
	.bg-home {
		background-image: url(@/assets/images/home-bg-desktop.svg);
		background-size: contain;
		background-position: bottom center;
		background-repeat: no-repeat;
	}

	.sun {
		transform: scale(1.6);
	}

	.butterfly-1 {
		left: 20vw;
		transform: scale(1.3);
	}

	.butterfly-2 {
		right: 24vw;
		bottom: 20vh;
		transform: scale(1.3);
	}

	.bird-1 {
		right: 25vw;
		top: 28vh;
		transform: scale(1.4);
	}

	.cloud-7 {
		transform: scale(1.5);
		bottom: 25vh;
		left: 18vw;
	}

	.button-start {
		width: 100%;
		bottom: 0vh;
		margin-bottom: 1rem;
	}
}

@media only screen and (min-width: 1101px) and (max-width: 1400px) {
	.bg-home {
		background-image: url(@/assets/images/home-bg-desktop.svg);
		background-size: contain;
		background-position: bottom center;
		background-repeat: no-repeat;
	}

	.butterfly-1 {
		left: 20vw;
		transform: scale(1.3);
	}

	.butterfly-2 {
		right: 24vw;
		bottom: 20vh;
		transform: scale(1.3);
	}

	.bird-1 {
		right: 25vw;
		top: 28vh;
		transform: scale(1.4);
	}

	.cloud-5 {
		top: 47vh;
		left: 36vw;
		transform: scale(1.5);
	}

	.cloud-7 {
		transform: scale(1.5);
		bottom: 17vh;
	}

	.button-start {
		width: 100%;
		bottom: 0vh;
	}
}

@media only screen and (min-width: 1401px) and (max-width: 1600px) {
	.bg-home {
		background-image: url(@/assets/images/home-bg-desktop.svg);
		background-size: contain;
		background-position: bottom center;
		background-repeat: no-repeat;
	}

	.header {
		top: 0vh;
	}

	.sun {
		transform: scale(1.2);
	}

	.butterfly-1 {
		transform: scale(1);
	}

	.butterfly-2 {
		transform: scale(1);
		right: 24vw;
	}

	.bird-1 {
		transform: scale(1);
	}

	.button-start {
		width: 100%;
		bottom: 2vh;
	}

	.button-start button {
		width: 80%;
	}

	.cloud-sm,
	.cloud-md,
	.cloud-lg {
		transform: scale(1);
	}

	.cloud-2,
	.cloud-4,
	.cloud-8 {
		display: none;
	}
}

</style>
	