import { createApp } from 'vue'
import { createWebHistory, createRouter } from 'vue-router'
import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import KonamiCode from "vue3-konami-code"
import mitt from 'mitt'
import SvgIcon from "vue3-icon"
import VueGtag from "vue-gtag"

import App from './App.vue'
import DevPage from './pages/DevPage.vue'
import HomePage from './pages/HomePage.vue'
import ProgressPage from './pages/ProgressPage.vue'
import QuestionPage from './pages/QuestionPage.vue'

const routes = [
	{ path: '/', component: HomePage, name: "Home" },
	{ path: '/dev', component: DevPage, name: "Development Dashboard" },
	{ path: '/progress', component: ProgressPage, name: "Progress" },
	{ path: '/questions/:id', component: QuestionPage, name: "Question", props: route => ({ title: `Question ${route.params.id}` }) },
]

const store = createStore({
	state: { activeVersion: 'desktop', hasStarted: false, hasCamera: false, checkedForCamera: false, soundMode: true, bossMode: false, redirectTo: null, answers: { question1: false, question2: false, question3: false, question4: false, question5: false, question6: false } },
	plugins: [createPersistedState()],
	mutations: {
		updateAnswer(state, payload) {
			if(payload.question) {
				console.log(`State: user has answered question ${payload.question}`, payload)
				state.answers[payload.question] = payload.correct
			}
		},
		updateBossMode(state, payload) {
			console.log('State: bossMode is now', payload)
			state.bossMode = payload
		},
		updateSoundMode(state, payload) {
			console.log('State: soundMode is now', payload)
			state.soundMode = payload
		},
		updateVersion(state, payload) {
			console.log('State: activeVersion is now', payload)
			state.activeVersion = payload
		},
		resetGame(state) {
			console.log(`State: game answers and settings have been reset`)
			state.answers = { question1: false, question2: false, question3: false, question4: false, question5: false, question6: false }
			state.checkedForCamera = false
			state.activeVersion = "desktop"
			state.soundMode = true
			state.hasStarted = false
			state.redirectTo = null
		},
		winGame(state) {
			console.log(`State: game has been won`)
			state.answers = { question1: true, question2: true, question3: true, question4: true, question5: true, question6: true }
			state.hasStarted = true
			state.redirectTo = null
		},
		updateCameraChecked(state, payload) {
			console.log(`State: checkedForCamera is now`, payload)
			state.checkedForCamera = payload
		},
		updateHasCamera(state, payload) {
			console.log(`State: hasCamera is now`, payload)
			state.hasCamera = payload
		},
		setState(state, payload) {
			console.log(`State: updated to`, payload)
			state = payload
		},
		resetCamera(state) {
			console.log(`State: camera resetted`)
			state.hasCamera = false
			state.checkedForCamera = false
		},
		setRedirectTo(state, payload) {
			console.log(`State: redirectTo is now`, payload)
			state.redirectTo = payload
		},
		setHasStarted(state, payload) {
			console.log(`State: hasStarted is now`, payload)
			state.hasStarted = payload
		}
	}
})

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return { top: 0 }
	},
})

const emitter = mitt()
const app = createApp(App)
app.component("svg-icon", SvgIcon)
app.config.globalProperties.emitter = emitter
app.use(router)
app.use(store)
app.use(VueGtag, {
	config: { 
		id: "G-D44EHVMR7Q",
		params: {
			anonymize_ip: true,
		},
		appName: 'Valley Park Game',
		pageTrackerScreenviewEnabled: true,
	}, router
})
app.use(KonamiCode, {
	onKonamiCodeEntered: function() {
		emitter.emit('konami-code-entered', true)
	}
});
app.mount('#app')
