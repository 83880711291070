<template>
    <svg-icon type="mdi" :path="iconPath" :size="size"></svg-icon>
</template>

<script>
import * as icons from '@mdi/js'

export default {
    props: {
        icon: {
            default: "Account",
            type: String,
        },
        size: {
            default: 48,
            type: Number,
        },
    },
    data() {
        return {
            pressed: false,
        }
    },
    computed: {
        iconPath() {
            return icons[`mdi${this.icon}`]
        }
    },
    setup() {
        
    },
    methods: {
    },
    mounted() {
    }
}
</script>