export default {
    //  Buttons
    buttons: {
        lets_start: "Let's Start!",
        return_to_start: "Return To Start Page",
        return_to_home: "Return To Home",
        try_again: "Try Again",
        play_again: "Play Again!",
        scan_now: "Scan Now",
        go_back: "Go Back",
        already_answered: "Got It!",
        warning_confirm: "I Agree",
        download_your_prize: "Download Your Prize",
    },

    //  Titles
    titles: {
        home: "The Valley Park Branch LEED (Leadership in Energy and Environmental Design) Scavenger Hunt",
        progress: "The Valley Park Branch\nLEED Scavenger Hunt",
        completed: "Way To Go!",
        already_answered: "Oops!",
        warning_confirm: "Confirm",
        scan_prompt: "Search for Scout on posters in the library to start the game and discover the eco-friendly features.",
        youre_right: "Right!",
        are_you_sure: "Are You Sure?",
    },

    //  Content
    content: {
        completed: "You answered everything correctly–that makes you a LEED expert! Visit the Valley Park Branch Information Desk or download your prize below.",
        correct_answer: "You're on track to becoming a LEED expert!",
        incorrect_answer: "Your answer was close—but not quite right. Please try again.",
        already_answered: "You've already answered that question!",
        warning_confirm: "We may store cookies.",
        progress_with_camera: "Hint: The 6 categories of LEED can be found on various pillars in the library.",
        progress_without_camera: "Find Scout in six spots in this library to learn about the Valley Park Branch's earth-friendly features.",
        progress_detecting: "Detecting camera...",
    }
}
