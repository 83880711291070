<template>
    <button :class="['lg:hover:-translate-y-1 lg:hover:scale-110 transition duration-300 ease-in-out m-auto rounded-full font-bold text-md lg:text-lg bg-black text-white px-6 lg:px-12 py-2 lg:py-4 pt-3 lg:pt-5 border-gray-700 border-b-4 flex flex-row justify-center items-center w-full', {'lg:effect lg:mt-1 lg:border-b-0': pressed}, this.class]" @mousedown="mouseDown" @mouseup="mouseUp" @click="$emit('click')">
        <slot></slot>
    </button>
</template>

<script>
import { useSound } from '@vueuse/sound'

export default {
    props: {
        sound: {
            default: 'swish',
            type: String,
        },
    },
    data() {
        return {
            pressed: false,
        }
    },
    setup(props) {
        const soundToLoad = require(`@/assets/sounds/${props.sound}.mp3`)
        const buttonClickSoundPlayer = useSound(soundToLoad, {volume: 0.1})

        return {
            buttonClickSoundPlayer,
        }
    },
    methods: {
        mouseDown(e) {
            this.pressed = true;
            this.$emit('mousedown')
        },
        mouseUp(e) {
            let that = this;

            if(this.$store.state.soundMode)
                this.buttonClickSoundPlayer.play()
                
            setTimeout(function() { 
                that.pressed = false;
            }, 150);
            
            this.$emit('mouseup')
        },
    }
}
</script>

<style scoped>
button {
    box-shadow: white 0 0 0 -9px, white 0 0 0 -9px, white 0 0 0 -9px, white 0 0 0 -17px, white 0 0 0 -9px;
}

.effect {
    box-shadow: white 0 0 30px -2px, white 0 0 30px -2px, white 0 0 30px -9px, white 0 0 30px -17px, white 0 0 30px -9px;
}
</style>